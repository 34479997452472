import { MenuPresetConfig } from '@davidhiu/ignite-ng';

export const getMenuPresetList = (): MenuPresetConfig['presetList'] => {
  const helpUrl = 'https://docs.google.com/document/d/1dNgJxW26vOPBP_71E42S-qNdIiIJ9EdprkoEJMhxqp4';

  return [
    {
      section: 'main',
      menus: [
        {
          title: 'Home',
          icon: 'home',
          path: '/home',
          helpUrl: helpUrl,
        },
        {
          title: 'Dashboard',
          icon: 'dashboard',
          path: '/dashboard',
          helpUrl: helpUrl,
          permission: 'menu.dashboard',
        },
        {
          title: 'Master',
          icon: 'folder_open',
          permission: '',
          subMenus: [
            {
              title: 'Component',
              icon: 'keyboard_arrow_right',
              path: '/master/component',
              helpUrl: helpUrl,
              permission: 'menu.master.component',
            },
            {
              title: 'Equipment',
              icon: 'keyboard_arrow_right',
              path: '/master/equipment',
              helpUrl: helpUrl,
              permission: 'menu.master.equipment',
            },
            {
              title: 'Purchasing Order',
              icon: 'keyboard_arrow_right',
              path: '/master/purchasing-order',
              helpUrl: helpUrl,
              permission: 'menu.master.purchasing-order',
            },
            {
              title: 'Purchasing',
              icon: 'keyboard_arrow_right',
              path: '/master/purchasing',
              helpUrl: helpUrl,
              permission: 'menu.master.purchasing',
            },
          ],
        },
        {
          title: 'Tire',
          icon: 'folder_open',
          permission: '',
          subMenus: [
            {
              title: 'Tire',
              icon: 'keyboard_arrow_right',
              path: '/tire/tire',
              helpUrl: helpUrl,
              permission: 'menu.tire.tire',
            },
            {
              title: 'Movement',
              icon: 'keyboard_arrow_right',
              path: '/tire/movement',
              helpUrl: helpUrl,
              permission: 'menu.tire.movement',
            },
            {
              title: 'Replacement',
              icon: 'keyboard_arrow_right',
              path: '/tire/replacement',
              helpUrl: helpUrl,
              permission: 'menu.tire.replacement',
            },
            {
              title: 'Audit',
              icon: 'keyboard_arrow_right',
              path: '/tire/audit',
              helpUrl: helpUrl,
              permission: 'menu.tire.audit',
            },
          ],
        },
      ],
    },
  ];
};
